export default {
    SPOTIFY_LINK: "https://open.spotify.com/artist/2Kqpf2Kuqkn1qvqGg88c7I",
    YT_LINK: "https://www.youtube.com/channel/UCU5IVVuCX8qXSErkpmM0EOg",
    AM_LINK: "https://music.apple.com/us/artist/alex-howe/1530105523",
    DISCORD_LINK: "https://discord.gg/XXquPZN",
    TELEGRAM_LINK: "https://t.me/alexhowe",
    INSTAGRAM_LINK: "https://instagram.com/zerozero1337",
    TWITTER_LINK: "https://twitter.com/unlimitedcoder2",
    GITHUB_LINK: "https://github.com/unlimitedcoder2",
	HACKERRANK_LINK: "https://www.hackerrank.com/unlimitedcoder2"
}