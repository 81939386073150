import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./styles/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";
import Home from "./pages/Home";

const App = () => {
	return (
		<Router>
			<header>
				<Navbar className="nav-bg" expand="lg">
					<Nav className="mr-auto">
						<Link className="nav-link white-btn" to="/">Home</Link>
						<Link className="nav-link white-btn" to="/projects">Projects</Link>
					</Nav>
				</Navbar>
			</header>

			<Route path="/" exact={true} render={props => <Home />} />
			<Route path="/projects" exact={true} render={props => <div>Coming soon!</div>} />

			<footer>
			</footer>
		</Router>
	)
}

export default App;
