import React from "react";
import { FaSpotify, FaYoutube, FaDiscord, FaTwitter, FaGithub, FaHSquare } from "react-icons/fa";
import Constants from "../Constants";
const Home = () => {
	return (
		<div className="home-container">
			<div className="home-bg-image" />

			<div className="home-info">
				<img src="/img/pfp.png" alt="profile pic" />
				<h1 style={{color: "white"}}>Alex Howe</h1>
				{/* <h3 style={{color: "white"}}>Developer</h3> */}
			</div>

			<ul className="home-socials">
				{/* Professional */}
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />

				<li><a href={Constants.GITHUB_LINK} target="_blank" className="socials-github" rel="noopener noreferrer"><FaGithub /> Github</a></li>
				<br />

				<li><a href={Constants.HACKERRANK_LINK} target="_blank" className="socials-hackerrank" rel="noopener noreferrer"><FaHSquare /> HackerRank</a></li>
				<br />

				<li><a href={Constants.DISCORD_LINK} target="_blank" className="socials-discord" rel="noopener noreferrer"><FaDiscord /> Discord</a></li>
				<br />

				<li><a href={Constants.YT_LINK} target="_blank" className="socials-yt" rel="noopener noreferrer"><FaYoutube /> Youtube</a></li>
				<br />
				
				<li><a href={Constants.TWITTER_LINK} target="_blank" className="socials-twitter" rel="noopener noreferrer"><FaTwitter /> Twitter</a></li>
				<br />
				
				<li><a href={Constants.SPOTIFY_LINK} target="_blank" className="socials-spotify" rel="noopener noreferrer"><FaSpotify /> Spotify</a></li>
			</ul>
		</div>
	)
}

export default Home;